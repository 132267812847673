const Wallets = ({ wallets }) => {
    // Ensure wallets is an array before rendering
    if (!wallets || wallets.length === 0) {
        return <p>No wallets found. Please create one!</p>
    }

    return (
        <div className="wallet-container">
            <h2>Your Wallets</h2>
            <ul>
                {wallets.map((wallet, idx) => (
                    <li key={idx} className="wallet-item">
                        {wallet.address} - Balance: {wallet.balance} Wei
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default Wallets
