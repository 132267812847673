import React, { useState } from 'react'
import { loginUser, registerUser } from '../actions/authActions'

const Auth = ({ setToken }) => {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    return (
        <div className="auth-form">
            <h2>Login / Register</h2>
            <input
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
            />
            <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <button onClick={() => loginUser(username, password, setToken)}>
                Login
            </button>
            <button onClick={() => registerUser(username, password)}>
                Register
            </button>
        </div>
    )
}

export default Auth
