import axios from 'axios'
import { jwtDecode } from 'jwt-decode'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

export const loginUser = async (username, password, setToken) => {
    try {
        const response = await axios.post(`${BACKEND_URL}/login`, {
            username,
            password,
        })
        const token = response.data.token
        setToken(token)
        localStorage.setItem('token', token)
    } catch (error) {
        console.error(error)
        alert('Login failed')
    }
}

export const registerUser = async (username, password) => {
    try {
        const response = await axios.post(`${BACKEND_URL}/register`, {
            username,
            password,
        })
        alert(response.data.message)
    } catch (error) {
        console.error(error)
    }
}

export const logoutUser = (setToken) => {
    setToken('')
    localStorage.removeItem('token')
    alert('You have been logged out')
}

export const checkTokenExpiration = (token) => {
    const decodedToken = jwtDecode(token)
    const currentTime = Date.now() / 1000
    return decodedToken.exp < currentTime
}
