import React, { useState } from 'react'
import { signTransaction } from '../actions/walletActions'

const SignTransaction = ({
    token,
    wallets,
    selectedWallet,
    setSelectedWallet,
}) => {
    const [message, setMessage] = useState('')
    const [signature, setSignature] = useState('')

    const handleSign = async () => {
        const signedMessage = await signTransaction(
            token,
            selectedWallet,
            message
        )
        setSignature(signedMessage)
    }

    return (
        <div className="auth-form">
            <h2>Sign Message</h2>
            <select
                onChange={(e) => setSelectedWallet(e.target.value)}
                value={selectedWallet}
            >
                <option value="">Select Wallet</option>
                {wallets.map((wallet) => (
                    <option key={wallet.address} value={wallet.address}>
                        {wallet.address}
                    </option>
                ))}
            </select>
            <input
                type="text"
                placeholder="Message to sign"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
            />
            <button onClick={handleSign}>Sign</button>
            {signature && <p className="signature">Signature: {signature}</p>}
        </div>
    )
}

export default SignTransaction
