import React, { useState } from 'react'
import { createWallet } from '../actions/walletActions'

const CreateWallet = ({ token, fetchWallets }) => {
    const [newWallet, setNewWallet] = useState('')

    const handleCreateWallet = async () => {
        const wallet = await createWallet(token)
        setNewWallet(wallet)
        fetchWallets()
    }

    return (
        <div className="auth-form">
            <h2>Create New Wallet</h2>
            <button onClick={handleCreateWallet}>Create New Wallet</button>
            {newWallet && (
                <p className="new-wallet">New Wallet Created: {newWallet}</p>
            )}
        </div>
    )
}

export default CreateWallet
