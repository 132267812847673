import React, { useState } from 'react'
import { sendEther } from '../actions/walletActions'

const SendEther = ({ token, wallets, selectedWallet, setSelectedWallet }) => {
    const [recipientAddress, setRecipientAddress] = useState('')
    const [amount, setAmount] = useState('')

    const handleSendEther = async () => {
        await sendEther(token, selectedWallet, recipientAddress, amount)
    }

    return (
        <div className="auth-form">
            <h2>Send Ether</h2>
            <select
                onChange={(e) => setSelectedWallet(e.target.value)}
                value={selectedWallet}
            >
                <option value="">Select Wallet</option>
                {wallets.map((wallet) => (
                    <option key={wallet.address} value={wallet.address}>
                        {wallet.address}
                    </option>
                ))}
            </select>
            <input
                type="text"
                placeholder="Recipient Address"
                value={recipientAddress}
                onChange={(e) => setRecipientAddress(e.target.value)}
            />
            <input
                type="text"
                placeholder="Amount in Wei"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
            />
            <button onClick={handleSendEther}>Send Ether</button>
        </div>
    )
}

export default SendEther
