import axios from 'axios'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

export const fetchWallets = async (token, setWallets) => {
    try {
        const response = await axios.get(`${BACKEND_URL}/wallets`, {
            headers: { Authorization: `Bearer ${token}` },
        })
        setWallets(response.data.wallets)
    } catch (error) {
        console.log(error)
    }
}

export const createWallet = async (token) => {
    try {
        const response = await axios.post(
            `${BACKEND_URL}/wallet/create`,
            {},
            {
                headers: { Authorization: `Bearer ${token}` },
            }
        )
        return response.data.wallet_address
    } catch (error) {
        console.log(error)
    }
}

export const signTransaction = async (token, address, message) => {
    try {
        const response = await axios.post(
            `${BACKEND_URL}/wallet/sign`,
            {
                address,
                message,
            },
            {
                headers: { Authorization: `Bearer ${token}` },
            }
        )
        return response.data.signature
    } catch (error) {
        console.log(error)
    }
}

export const sendEther = async (token, wallet, to, amount) => {
    try {
        const response = await axios.post(
            `${BACKEND_URL}/wallet/send`,
            {
                to,
                amount,
                wallet,
            },
            {
                headers: { Authorization: `Bearer ${token}` },
            }
        )
        alert(`${response.data.message}, TxHash: ${response.data.txHash}`)
    } catch (error) {
        console.log(error)
    }
}
