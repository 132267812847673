import React, { useState, useEffect } from 'react'
import { fetchWallets } from './actions/walletActions'
import { logoutUser, checkTokenExpiration } from './actions/authActions'
import Auth from './components/Auth'
import Wallets from './components/Wallets'
import CreateWallet from './components/CreateWallet'
import SignTransaction from './components/SignTransaction'
import SendEther from './components/SendEther'
import './App.css'

const App = () => {
    const [activeTab, setActiveTab] = useState('wallets')
    const [token, setToken] = useState(localStorage.getItem('token') || '')
    const [wallets, setWallets] = useState([])
    const [selectedWallet, setSelectedWallet] = useState('')

    useEffect(() => {
        if (token) {
            if (checkTokenExpiration(token)) {
                logoutUser(setToken)
            } else {
                fetchWallets(token, setWallets)
            }
        }
    }, [token])

    return (
        <div className="App">
            <div className="auth-container">
                <h1>Crypto Wallet Manager</h1>

                {token ? (
                    <div>
                        <button
                            className="logout-btn"
                            onClick={() => logoutUser(setToken)}
                        >
                            Logout
                        </button>

                        <div className="tabs">
                            <button onClick={() => setActiveTab('wallets')}>
                                Your Wallets
                            </button>
                            <button onClick={() => setActiveTab('create')}>
                                Create Wallet
                            </button>
                            <button onClick={() => setActiveTab('sign')}>
                                Sign Transaction
                            </button>
                            <button onClick={() => setActiveTab('send')}>
                                Send Ether
                            </button>
                        </div>

                        {activeTab === 'wallets' && (
                            <Wallets wallets={wallets} />
                        )}
                        {activeTab === 'create' && (
                            <CreateWallet
                                token={token}
                                fetchWallets={() =>
                                    fetchWallets(token, setWallets)
                                }
                            />
                        )}
                        {activeTab === 'sign' && (
                            <SignTransaction
                                token={token}
                                wallets={wallets}
                                selectedWallet={selectedWallet}
                                setSelectedWallet={setSelectedWallet}
                            />
                        )}
                        {activeTab === 'send' && (
                            <SendEther
                                token={token}
                                wallets={wallets}
                                selectedWallet={selectedWallet}
                                setSelectedWallet={setSelectedWallet}
                            />
                        )}
                    </div>
                ) : (
                    <Auth setToken={setToken} />
                )}
            </div>
        </div>
    )
}

export default App
